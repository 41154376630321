import styled from 'styled-components';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { BaseMenu } from '@app/components/common/BaseMenu/BaseMenu';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

export const Menu = styled(BaseMenu)`
  background: transparent;
  padding: 0 0.875rem;
  height: 2.5rem !important;

  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
    border-radius: 0.5rem !important;
  }

  .ant-menu-submenu {
    position: relative;
  }

  .ant-menu-submenu > .ant-menu {
    background: transparent !important;
    padding-left: 2.2rem !important;
    position: relative;
  }

  .ant-menu-submenu > .ant-menu::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1.5rem;
    width: 1px;
    background-color: var(--blue-900);
    display: none;
  }

  .ant-menu-submenu.ant-menu-submenu-open > .ant-menu::before {
    display: block;
  }

  .ant-menu-submenu.ant-menu-submenu-open > .ant-menu::after {
    content: '';
    position: absolute;
    left: 1.5625rem;
    top: var(--dot-position, 50%) !important;
    width: 5px;
    height: 5px;
    background-color: var(--white);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: none;
    transition: top 0.2s ease;
  }

  .ant-menu-submenu-selected.ant-menu-submenu-open > .ant-menu::after {
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    height: 2.5rem !important;
    padding-left: 0.90625rem !important;
  }

  .ant-menu-item-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: -0.5rem;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role='img'],
  a,
  .ant-menu-item,
  .ant-menu-submenu {
    color: var(--text-sider-secondary-color);
    fill: var(--text-sider-secondary-color);
  }

  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    background-color: var(--blue-1000) !important;
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    a,
    .ant-menu-item-icon,
    .ant-menu-title-content {
      color: var(--text-sider-primary-color);
      fill: var(--text-sider-primary-color);
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: var(--text-sider-primary-color);
      background-color: var(--blue-800) !important;
      border-radius: 0.5rem;
      height: 2.5rem !important;

      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow,
      span[role='img'] {
        color: var(--text-sider-primary-color);
        fill: var(--text-sider-primary-color);
      }
    }
  }

  .ant-menu-item-selected {
    background-color: var(--blue-900) !important;
    border-radius: 0.5rem;
    height: 2.5rem !important;

    &::after {
      display: none !important;
    }

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    .ant-menu-item-icon,
    a {
      color: var(--text-sider-primary-color);
      fill: var(--text-sider-primary-color);
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    border-radius: 0.5rem !important;
  }
`;

export const SiderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 24px;
`;
export const MenuContainer = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  max-height: calc(100% - 60px);
`;

export const Title = styled(BaseTypography)`
  font-size: 0.775rem;
  font-weight: 400;
  color: #b0b0b0;
`;
