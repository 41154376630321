import { useCallback } from 'react';

const useDynamicDotPositioning = (menuRef: React.RefObject<HTMLDivElement>) => {
  const updateDotPosition = useCallback(
    (selectedKey: string) => {
      if (!menuRef.current) return;

      // select all open submenu elements within the menu container
      const openSubmenus = menuRef.current.querySelectorAll('.ant-menu-submenu.ant-menu-submenu-open');

      openSubmenus.forEach((openSubmenu) => {
        const submenuMenu = openSubmenu.querySelector('.ant-menu');
        if (!submenuMenu) return;

        // find the selected menu item within this submenu using a data attribute
        const selectedItem = submenuMenu.querySelector(`.ant-menu-item[data-menu-id*='${selectedKey}']`);
        if (!selectedItem) return;

        // calculate the dot's top position relative to the submenu container
        const submenuRect = submenuMenu.getBoundingClientRect();
        const itemRect = selectedItem.getBoundingClientRect();
        const dotTop = itemRect.top - submenuRect.top + itemRect.height / 2;

        // update a CSS variable to position the dot
        (submenuMenu as HTMLElement).style.setProperty('--dot-position', `${dotTop}px`);
      });
    },
    [menuRef],
  );

  return updateDotPosition;
};

export default useDynamicDotPositioning;
