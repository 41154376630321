import { Priority } from '@app//constants/enums/priorities';
import { getBlobFileUrl, uploadFileToStorage } from '@app/api/azure-storage.api';
import maestro from '@app/assets/images/card-issuers/maestro.png';
import mastercard from '@app/assets/images/card-issuers/mastercard.png';
import visa from '@app/assets/images/card-issuers/visa.png';
import { TOption } from '@app/components/apps/manage-recommendations/RecommendationForm';
import { BaseBadgeProps } from '@app/components/common/BaseBadge/BaseBadge';
import { NotificationType } from '@app/components/common/BaseNotification/BaseNotification';
import { TQueryParams } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns, TFilterValue } from '@app/components/tables/AntdTableWrapper/types';
import { TColumnHasEnumFilter, TTextOptionsFilter } from '@app/components/tables/data-table/types';
import { currencies } from '@app/constants/config/currencies';
import { ALLOWED_DELETED_STATUS_KEYS } from '@app/constants/enumValues';
import { UserModel } from '@app/domain/UserModel';
import i18next from '@app/i18n';
import { CurrencyTypeEnum, Severity } from '@app/interfaces/interfaces';
import { getEnumValue } from '@app/services/enum.service';
import { store } from '@app/store/store';
import { TAlertsHistoryExtended } from '@app/types/alertsHistoryExtended';
import { TChanges } from '@app/types/changelogs';
import { TEnumEntity } from '@app/types/enumEntity';
import { TRoleAndScreen } from '@app/types/roleAndScreen';
import { TSubscriptionType } from '@app/types/subscriptionType';
import { notification } from 'antd';
import { differenceInMonths, format, formatDistanceToNow, parse, subDays, subMonths } from 'date-fns';

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const getCurrencyPrice = (price: number | string, currency: CurrencyTypeEnum, isIcon = true): string => {
  const currencySymbol = currencies[currency][isIcon ? 'icon' : 'text'];

  return isIcon ? `${currencySymbol}${price}` : `${price} ${currencySymbol}`;
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const msToH = (ms: number): number => Math.floor(ms / 3600000);

export const hToMS = (h: number): number => h * 3600000;

export const getPaymentCardTypeIcon = (type: string): string | null => {
  switch (type) {
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'maestro':
      return maestro;
    case 'amex':
      return 'amex';
    case 'discover':
      return 'discover';
    case 'diners':
      return 'diners';
    case 'jcb':
      return 'jcb';
    case 'unionpay':
      return 'unionpay';
    default:
      return null;
  }
};

export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info';
  }

  return status;
};

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isScreenAllowed = (
  screen: string,
  dashboardScreenValues: Record<string, number>[],
  rolesAndScreens: TRoleAndScreen[],
  userRole: number,
  sidebarItemGroup?: number,
): boolean => {
  const screenValue = dashboardScreenValues?.find((scr: any) => {
    const normalizedScreen = screen.split('/').pop()?.replace('-', '').toLowerCase();
    return scr.label.toLowerCase() === normalizedScreen;
  })?.value;

  if (!!sidebarItemGroup) {
    return (
      rolesAndScreens.find(
        (ras) => ras.role === userRole && ras.screen === screenValue && ras.group === sidebarItemGroup,
      ) != null
    );
  }

  return rolesAndScreens.find((ras) => ras.role === userRole && ras.screen === screenValue) != null;
};

export const getProductLabel = (productName: string) => {
  const products = store.getState()?.app?.products as TEnumEntity[];
  return (
    products?.find((product) => product.name.toLowerCase() === productName.toLowerCase())?.displayName || productName
  );
};

export const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator

  return !!urlPattern.test(urlString);
};

export type TimeFrameOptions = 'last7days' | 'last30days' | 'last90days' | 'last12months' | 'alltime';
export const getTimeFrameFromOption = (value: TimeFrameOptions) => {
  if (value === 'last7days') {
    return {
      from: subDays(new Date(), 7),
      to: new Date(),
    };
  }

  if (value === 'last30days') {
    return {
      from: subDays(new Date(), 30),
      to: new Date(),
    };
  }

  if (value === 'last90days') {
    return {
      from: subDays(new Date(), 90),
      to: new Date(),
    };
  }

  if (value === 'last12months') {
    return {
      from: subMonths(new Date(), 12),
      to: new Date(),
    };
  }

  return {
    // some minimum date to get the effect of "all time"
    from: new Date('0001-01-01T00:00:00Z'),
    to: new Date(),
  };
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getFileBufferArray = async (file: File) => {
  const fileBuffer = await file.arrayBuffer();
  const fileBufferArray = new Uint8Array(fileBuffer);
  return fileBufferArray;
};

export const displayMissingPermissionsNotification = () => {
  const notificationKey = 'missingPermissionsNotification';
  notification.open({
    key: notificationKey,
    message: 'Your account is missing permissions. Please contact Griffin31 support.',
    type: 'error',
    duration: 0, // do not close automatically
    onClose: () => {
      notification.close(notificationKey);
    },
  });
};

export const convertAlertHistoryDataToTimelineData = (data: TAlertsHistoryExtended[], placeHolderDate: Date) => {
  const alertIds = Array.from(new Set(data.map((d) => d.alertId)));

  const timelineData = alertIds.map((id) => {
    const alertName = data.filter((d) => d.alertId === id)[0].alertName;
    const dataPoints = data
      .filter((d) => d.alertId === id)
      .map((d) => {
        const isBecameNonActive = d.changeType.toLowerCase().includes('non-active');

        return {
          becameNonActive: isBecameNonActive,
          date: d.creationTime as unknown as string,
        };
      }) as {
      becameNonActive?: boolean;
      date: string;
    }[];

    if (dataPoints.length % 2 !== 0) {
      if (dataPoints.at(-1)?.becameNonActive) {
        dataPoints.push({
          becameNonActive: undefined,
          date: format(new Date(placeHolderDate), 'dd/MM/yyyy HH:mm:ss'),
        });
      } else {
        dataPoints.push({
          becameNonActive: undefined,
          date: format(new Date(placeHolderDate), 'dd/MM/yyyy HH:mm:ss'),
        });
      }
    }

    return {
      x: alertName,
      y: dataPoints,
    };
  });

  const groupedTimelineData = timelineData.flatMap((data) => {
    const newYData = [...data.y];
    const groupedY = newYData.reduce((newDataPoints, currentDataPoint, currentIndex, dataPointArray) => {
      if (currentIndex === 0 || (currentIndex - 1) % 2 !== 0) {
        const groupedDataPoint = [
          {
            ...currentDataPoint,
            date: currentDataPoint.date,
          },
          {
            ...dataPointArray[currentIndex + 1],
            date: dataPointArray[currentIndex + 1].date,
          },
        ];
        newDataPoints.push(groupedDataPoint);
        return newDataPoints;
      }

      return newDataPoints;
    }, [] as { date: string; becameNonActive?: boolean }[][]);

    const seriesData = groupedY.map((y) => ({
      x: data.x,
      y: y,
    }));

    return seriesData;
  });

  return groupedTimelineData;
};

export const convertAlertTimelineDataToSeriesData = (
  data: ReturnType<typeof convertAlertHistoryDataToTimelineData>,
  barColors: string[],
) => {
  const ret = data.map((d, index) => {
    return {
      ...d,
      // ApexCharts' datetime axis don't work well with large timestamp values
      // so we use epoch in hours instead of milliseconds
      y: d.y
        .map((obj) => {
          let date: Date;
          if (obj.date.includes('T')) {
            date = new Date(obj.date);
          } else {
            date = parse(obj.date, 'dd/MM/yyyy HH:mm:ss', new Date());
          }
          return date.getTime() / (1000 * 3600);
        })
        .sort((a, b) => a - b),
      strokeColor: barColors[index],
    };
  });
  return ret;
};

export const replaceSpecialChars = (stringValue: string) => {
  let val = stringValue;

  val = val.replace(/'/g, "''");

  val = val.replace(/%/g, '%25');
  val = val.replace(/\//g, '%2F');
  val = val.replace(/\?/g, '%3F');

  val = val.replace(/#/g, '%23');
  val = val.replace(/&/g, '%26');
  val = val.replace(/"/g, '%34');

  // replace again the % character because the converted characters have % in them also
  val = val.replace(/%/g, '%25');

  return val;
};

/*
 * Function to check if two dates are for the same day
 * @param timestamp1 - first date
 * @param timestamp2 - second date
 * @returns boolean - true if the dates are for the same day, false otherwise
 * */
export const isSameDate = (timestamp1: number, timestamp2: number) => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

/*
 * TODO - remove multiple events from the same day
 * @param data - timeline data
 * @returns - grouped timeline data
 *
 */
export const groupTimeLineDates = (data: any) => {
  return data;
};

export function getDetailedChanges(changes: TChanges): string {
  if (changes.changeType === 'update') {
    const fieldChanges = Object.keys(changes.changes.oldValue)
      .map(
        (key) =>
          `${key} changed from ${JSON.stringify(changes.changes.oldValue[key])} to ${JSON.stringify(
            changes.changes.newValue[key],
          )}`,
      )
      .join(', ');
    return `${changes.modelDisplayName} ${changes.changes.id == 'Global' ? '' : changes.changes.id}: ${fieldChanges}`;
  }
  if (changes.changeType === 'create') {
    const fieldChanges = Object.keys(changes.changes.data)
      .map((key) => `${key}=${changes.changes.data[key]}`)
      .join(', ');
    return `${changes.modelDisplayName} ${changes.changes.id == 'Global' ? '' : changes.changes.id} was created${
      fieldChanges.length > 0 ? ' with: ' + fieldChanges : '.'
    }`;
  }

  const fieldChanges = Object.keys(changes.changes.data)
    .map((key) => `${key}=${changes.changes.data[key]}`)
    .join(', ');
  return `${changes.modelDisplayName} ${
    changes.changes.id == 'Global' ? '' : changes.changes.id
  } was deleted with: ${fieldChanges}`;
}

export const getDefaultShownColumns = <T>(defaultDisplayedDataIndexes: string[], tableColumns: TDataColumns<T>) => {
  const defaultDisplayedColumns = defaultDisplayedDataIndexes.map((defaultColumn) => {
    const tableCol = tableColumns.find((tCol) => tCol.dataIndex === defaultColumn);

    return { ...tableCol, hideColumn: false };
  });

  const hiddenColumns = tableColumns
    .filter((tCol) => !defaultDisplayedDataIndexes.includes(tCol.dataIndex as string))
    .map((tCol) => ({ ...tCol, hideColumn: true }));

  return [...defaultDisplayedColumns, ...hiddenColumns];
};

export const convertSelectUrlToColumns = <T>(
  columns: TDataColumns<T>,
  selectUrl = new URLSearchParams(window.location.search).get('$select'),
) => {
  if (!selectUrl) return [];

  return (
    selectUrl?.split(',').map((val) => {
      const column = columns.find((col) => col.dataIndex === val);

      if (column) {
        return column;
      }

      return undefined;
    }) || []
  );
};

export const constructUrlFromState = <T>(queryParams: TQueryParams, columnsState: TDataColumns<T>) => {
  const { top, skip, orderBy, filterValues, expand } = queryParams;

  const allFilters = filterValues
    .filter((f) => f.type !== 'searchableColumn')
    .sort((a, b) => (a.type === 'text' || a.type === 'textOptions' ? -1 : 1))
    .map((f) => {
      if (f.type === 'number') return `(${f.column} ${f.value})`;

      if (f.type === 'boolean') return `(${f.column} eq ${f.value})`;

      if (f.type === 'enum') {
        let value: number[] = JSON.parse(f.value);
        value = (Array.isArray(value) ? value : [value]) as number[];
        return `(${value.map((val) => `${f.column} eq ${val}`).join(' or ')})`;
      }

      if (f.type === 'textOptions' && !!f.multipleSelect) {
        return `(${(JSON.parse(f.value) as string[])
          .map((val) => `contains(tolower(${f.column}),'${val}')`)
          .join(' or ')})`;
      }

      if (f.type === 'enumArray') {
        return `(${(JSON.parse(f.value) as number[]).map((val) => `(contains(${f.column},'-${val}-'))`).join(' or ')})`;
      }

      if (f.type === 'date') {
        const dates = JSON.parse(f.value);
        return `(${f.column} ge ${format(dates[0], 'yyyy-MM-dd')} and ${f.column} le ${format(
          dates[1],
          'yyyy-MM-dd',
        )})`;
      }

      return `(contains(tolower(${f.column}),'${replaceSpecialChars(f.value.toLowerCase().replaceAll("''", "'"))}'))`;
    })
    .join(' and ');

  const concatenatedFilters = [allFilters].filter((f) => f !== '').join(' and ');

  const shownColumns = columnsState
    .filter((col) => (col.dataIndex !== undefined || col.dataIndex !== undefined) && !col.hideColumn)
    .map((col) => col.dataIndex)
    .join(',');

  const queryString = `${filterValues.length > 0 ? `$filter=${concatenatedFilters}` : ''}${
    orderBy ? `&$orderby=${orderBy.column} ${orderBy.order}` : ''
  }${
    filterValues.length === 0 && !orderBy ? `$select=${shownColumns}` : `&$select=${shownColumns}`
  }&$top=${top}&$skip=${skip}${expand && expand.length > 0 ? `&$expand=${expand.join(',')}` : ''}`;

  return queryString;
};

export const constructFilterStateFromUrl = <T>(urlSearchParams: URLSearchParams, columns: TDataColumns<T>) => {
  if (!urlSearchParams) {
    return undefined;
  }
  const FILTER_PARAMS_KEY = '$filter';
  const ADDED_FILTERS_KEY = '$added';

  const filterValues =
    (urlSearchParams
      .get(FILTER_PARAMS_KEY)
      ?.replaceAll('contains', '')
      ?.replaceAll('tolower', '')
      ?.split(/[()]+/)
      .join('')
      .split(' and ')
      .map((filter) => {
        const key = filter.replace('contains', '').replace(/['()]/g, '').replaceAll(' ', ',').split(',')[0];
        const filterCol = columns.find((col) => col.dataIndex === key);

        if (!filterCol) return undefined;

        let value = filter.replace(/[()]/g, '').split(' eq ')[1];

        if ((filterCol as any).type === 'number') {
          value = filter.split(' ').slice(1).join(' ');
        }

        if ((filterCol as any).type === 'enumArray') {
          const ids = filter.replace(/[()]/g, '').match(/\d+/g);
          value = JSON.stringify(Array.from(new Set(ids)));
        }

        if ((filterCol as any).type === 'enum') {
          const enumValues = filter.split(' or ').map((splitFilter) => splitFilter.split(' eq ').at(-1)?.trim());
          value = JSON.stringify(Array.from(new Set(enumValues)));
        }

        if ((filterCol as any).type === 'text') {
          value = filter.replace(/[()]/g, '').split(',').slice(1).join().slice(1, -1);
        }

        if ((filterCol as any).type === 'textOptions') {
          const textOptionValue =
            urlSearchParams
              .get(FILTER_PARAMS_KEY)
              ?.replaceAll('contains', '')
              ?.replaceAll('tolower', '')
              .split(/[()]+/)
              .join('') || ''; // the value for textOptions is derived differently since the value might contain the word "and" where it is split above.

          if ((filterCol as any).multipleSelect) {
            const regex = /'(.*?)'/g;
            const matches = [];
            let match;
            while ((match = regex.exec(textOptionValue.replace(/[()]/g, ''))) !== null) {
              matches.push(match[1]);
            }

            value = JSON.stringify(matches);
          } else {
            value = textOptionValue.replace(/[()]/g, '').split(',').slice(1).join().slice(1, -1);
          }
        }

        if ((filterCol as any).type === 'date') {
          value = JSON.stringify(filter.split(' and ').map((dateFilter) => dateFilter.split(' ')[2]));
        }

        return {
          title: typeof filterCol?.title === 'string' ? filterCol?.title : filterCol?.altLabel,
          column: filterCol?.dataIndex,
          type: (filterCol as any).type,
          value,
          ...(((filterCol as any).type === 'enum' || (filterCol as any).type === 'enumArray') && {
            enumKey: (filterCol as any).enumValuesKey,
          }),
          ...((filterCol as any).type === 'textOptions' && {
            options: (filterCol as any).options,
            multipleSelect: !!(filterCol as any).multipleSelect,
          }),
          ...((filterCol as any).type === 'boolean' && {
            hasNullOption: (filterCol as any).hasNullOption,
          }),
        };
      })
      .filter((f) => !!f) as TFilterValue[]) || [];

  /*
   * group date filters by column and combine their values
   * (fix for duplicate date columns in filterValues queryParams)
   */
  const dateFilterValues: {
    [column: string]: { filterValue: TFilterValue; dates: string[] };
  } = {};
  const otherFilterValues: TFilterValue[] = [];

  for (const filterValue of filterValues) {
    if (filterValue.type === 'date') {
      const column = filterValue.column;
      if (!dateFilterValues[column]) {
        // initialize date filter values
        dateFilterValues[column] = { filterValue: { ...filterValue }, dates: [] };
      }
      // combine date values into dates array
      const dates = JSON.parse(filterValue.value) as string[];
      dateFilterValues[column].dates.push(...dates);
    } else {
      otherFilterValues.push(filterValue);
    }
  }

  // process combined date filters
  const combinedDateFilterValues = Object.values(dateFilterValues).map(({ filterValue, dates }) => {
    // remove dups and sort
    const uniqueDates = Array.from(new Set(dates)).sort();
    // assign json back to value
    filterValue.value = JSON.stringify(uniqueDates);
    return filterValue;
  });

  // merge date filters with other filters
  const mergeFilterValues = [...otherFilterValues, ...combinedDateFilterValues];

  const addedFilters = JSON.parse(
    (new URLSearchParams(window.location.search).get(ADDED_FILTERS_KEY) as string) || '[]',
  ) as unknown as TFilterValue[];

  mergeFilterValues.push(...addedFilters);

  return mergeFilterValues.sort((a, b) => a.title!.localeCompare(b!.title as string));
};

export const getScreenResolution = () => {
  const width = window.screen.width;
  const height = window.screen.height;

  return { width, height };
};

/**
 * Used to upload an array of media files to storage and returns their corresponding blob URLs.
 *
 * 1. Iterates through each file in the array of File objects.
 * 2. Validates file if it exists in the array and its size is less than 25MB.
 * 3. Converts the file into a buffer array for uploading.
 * 4. Gets the URL as a blob for the uploaded file.
 * 5. Collects the blob URL into an array of successfully uploaded media URLs.
 *
 * @param {File[]} files - Array of File (media files) objects representing the files to be uploaded.
 * @returns {Promise<stringp[]>} - Promise that resolves to an array of blob URLs for the successfully uploaded files.
 *
 * @example
 * const files = [file1, file2, file3];
 * uploadMediaToStorage(files).then((mediaUrls) => {
 *   console.log('Uploaded media URLs:', mediaUrls);
 * }).catch((error) => {
 *   console.error('Error uploading media:', error);
 * });
 *
 **/
export const uploadMediaToStorage = async (files: File[], containerName: string): Promise<string[]> => {
  const mediaBlobUrls: string[] = [];

  for (const file of files) {
    if (!file || file.size > 25 * 1024 * 1024) {
      alert(`File size exceeded the limit of 25MB: ${file.name}`);
      continue;
    }

    const fileName = `${file.name}`;
    const fileBufferArray = await getFileBufferArray(file);
    const uploadUrl = await uploadFileToStorage(fileName, file.type, fileBufferArray, containerName);

    if (!uploadUrl) {
      alert(`Failed to upload the file: ${file.name}`);
      continue;
    }

    const mediaBlobUrl = getBlobFileUrl(fileName, containerName);
    if (!mediaBlobUrl) {
      alert(`Failed to retrieve media URL: ${file.name}`);
      continue;
    }

    mediaBlobUrls.push(mediaBlobUrl);
  }

  return mediaBlobUrls;
};

/**
 * converts a string date to a readable past date
 * e.g. 25/10/2024 -> 5 days ago
 */
export const convertDateToReadablePastDate = (date: string) => {
  const parsedDate = new Date(date);
  const isOlderThanOneMonth = differenceInMonths(new Date(), parsedDate) >= 1;
  return isOlderThanOneMonth ? format(parsedDate, 'dd/MM/yyyy') : formatDistanceToNow(parsedDate, { addSuffix: true });
};

export const stripHtml = (html: string): string => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

/**
 * converts a string date to match the format 'dd MMM, yyyy, HH:mm a'
 * e.g. 11 Dec, 2024, 12:30 pm
 */
export const formatDate = (dateString: string | Date): string => {
  const date = typeof dateString === 'string' ? new Date(dateString) : dateString;

  const day = date.toLocaleString('en-US', { day: '2-digit' });
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.toLocaleString('en-US', { year: 'numeric' });
  const time = date
    .toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .replace('AM', 'am')
    .replace('PM', 'pm');

  return `${day} ${month} ${year}, ${time}`;
};

/**
 * converts a string date to match the format 'dd/MM/yyyy'
 * e.g. 11/12/2024
 */
export const formatDateToDDMMYYYY = (date: string | Date): string => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    return '';
  }
  const day = parsedDate.getDate().toString().padStart(2, '0');
  const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
  const year = parsedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

const getEnumValues = (enumKey: string, value: string) => {
  const appValues = store.getState().app.appValues;

  let enumValues = JSON.parse(value) as string[];
  enumValues = Array.isArray(enumValues) ? enumValues : [enumValues];
  const stringEnumValues = enumValues
    .map((val) =>
      i18next.t(
        `appValues.${enumKey || ''}.${
          (appValues as any)?.[enumKey || '']?.find((appVal: any) => appVal.value == val)?.label
        }`,
      ),
    )
    .join(', ');

  return stringEnumValues;
};

export const getColumnStringValue = <T extends Record<string, any>>(
  columns: TDataColumns<T>,
  dataIndex: string,
  filterValue: string,
) => {
  const filterColumn = columns.find((col) => col.dataIndex === dataIndex);
  const columnType = filterColumn?.type;

  // default assumption is the value is text
  let value = filterValue;

  if (columnType === 'enum' || columnType === 'enumArray') {
    const enumKey = (filterColumn as TColumnHasEnumFilter).enumValuesKey;
    const mapEnumLabels = (filterColumn as TColumnHasEnumFilter).mapEnumLabels;

    enumKey === 'BooleanEnum'
      ? (value = getBooleanEnumLabel(filterValue.replaceAll("'", ''), mapEnumLabels || []))
      : (value = getEnumValues(enumKey, filterValue.replaceAll("'", '')));
  }

  if (columnType === 'textOptions' && !!(filterColumn as TTextOptionsFilter).multipleSelect) {
    const keys: string[] = JSON.parse(filterValue);
    const textOptionValues = keys
      .map(
        (v) => (filterColumn as TTextOptionsFilter).options.find((o) => o.key.toLowerCase() == v.toLowerCase())?.value,
      )
      .filter((v) => !!v) // remove undefined values
      .join(', ');

    value = textOptionValues;
  }

  if (columnType === 'date') {
    const dateValues = JSON.parse(filterValue);
    value = `${format(dateValues[0], 'dd/MM/yyy')} - ${format(dateValues[1], 'dd/MM/yyy')}`;
  }

  if (columnType === 'boolean') {
    if (value == 'null') {
      value = 'N/A';
    } else {
      value = capitalize(filterValue);
    }
  }

  return value;
};

/**
 * Returns the styles needed for table rows to be one liners
 * e.g. 11/12/2024
 */

export const getOneLineStyle = (maxWidth?: string) => {
  const defaultMaxWidth = '7vw';
  return {
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: maxWidth || defaultMaxWidth,
    },
  };
};

/**
 * Sets the date to "midnight UTC" for the same calendar day
 * as whatever date/time is passed in.
 */
export function startOfDayUTC(dateInput: string | Date): Date {
  const d = new Date(dateInput); // parse the incoming date/time in JS
  d.setUTCHours(0, 0, 0, 0); // set to midnight *UTC*
  return d; // now d is e.g. "2024-12-11T00:00:00.000Z" in UTC
}

export function endOfDayUTC(dateInput: string | Date): Date {
  const d = new Date(dateInput);
  d.setUTCHours(23, 59, 59, 999);
  return d; // e.g. "2024-12-11T23:59:59.999Z" in UTC
}

//Returns true iff user role has privliged access (e.g. not a regular, account level user)
export function isPrivliged(user: UserModel): boolean {
  return [getEnumValue('UserRole', 'SuperAdmin'), getEnumValue('UserRole', 'Vendor')].includes(user.role);
}

/**
 * Retrieves the mapped label for a given BooleanEnum filter value.
 *
 * @param filterValue - The value to look up.
 * @param mapEnumLabels - Array of mapping objects containing keys and new labels.
 * @returns The corresponding new label if found, otherwise the original filter value.
 */
export function getBooleanEnumLabel(filterValue: string, mapEnumLabels: { value: string; label: string }[]): string {
  let values: string[] = [];

  try {
    values = JSON.parse(filterValue);
  } catch (error) {
    values = [filterValue];
  }

  const mappedLabels = values.map((value) => {
    const mapping = mapEnumLabels.find((item) => item.value === value);
    return mapping ? mapping.label : value;
  });

  return mappedLabels.join(', ');
}

/**
 * Retrieves an array of enum items from appValues and applies new labels if a mapping is provided
 * For BooleanEnum, the mapping is applied to the values directly.
 */
interface IUseMappedEnumProps {
  enumKey: string;
  mapEnumLabels?: { value: string; label: string }[];
}

export function getMappedEnumLabels({ enumKey, mapEnumLabels }: IUseMappedEnumProps) {
  const appValues = store.getState().app.appValues;

  if (enumKey === 'BooleanEnum') {
    return mapEnumLabels ?? [];
  }

  const rawEnumArray = (appValues as any)?.[enumKey] ?? [];
  if (!mapEnumLabels) return rawEnumArray;

  return rawEnumArray.map((item: any) => {
    const newMappedLabels = mapEnumLabels.find((m) => m.value === item.value);
    return newMappedLabels ? { ...item, label: newMappedLabels.label } : item;
  });
}

export function getEnumStatusOptions(statusKey: string) {
  const statuses = (store.getState().app.appValues as any)?.[statusKey] as TOption[];

  return statuses?.filter((status) => ALLOWED_DELETED_STATUS_KEYS.includes(statusKey) || status.label !== 'Deleted');
}

/**
 * Maps the subscription types to options for the select component.
 * @param hasNoneOption - whether to include a "None" option in the select component
 * @returns the options for the select component
 */
export function getSubscriptionTypeOptions(hasNoneOption = false): TOption[] {
  const subscriptionTypes = store.getState().app.subscriptionTypes as TSubscriptionType[];

  const activeOptions =
    subscriptionTypes
      ?.filter((subscription) => subscription.status === 1)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((product) => ({
        value: product.id,
        label: product.name,
      })) || [];

  if (hasNoneOption) {
    activeOptions.unshift({ value: null as any, label: 'None' });
  }

  return activeOptions;
}

export function splitCamelCase(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, '$1 $2');
}
