import { ProfileDropdown } from '@app/components/header/components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { getEnumValue } from '@app/services/enum.service';
import { IApplicationState } from '@app/store/slices/appSlice';
import { UserState } from '@app/store/slices/userSlice';
import { RootState } from '@app/store/store';
import { isScreenAllowed } from '@app/utils/utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { SidebarNavigationItem, useSideBarNavigation } from '../sidebarNavigation';
import * as S from './SiderMenu.styles';
import useDynamicDotPositioning from './useDynamicDotPositioning';

interface SiderContentProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ isCollapsed, setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const appState = useSelector<RootState>((state) => state.app) as IApplicationState;
  const userState = useSelector<RootState>((state) => state.user) as UserState;
  const navItems = useSideBarNavigation();

  const menuRef = useRef<HTMLDivElement>(null);
  const updateDotPosition = useDynamicDotPositioning(menuRef);

  const selectedKeys = useMemo(() => {
    const keys: string[] = [];
    if (location.pathname.includes('dashboard')) keys.push('Home');

    navItems.forEach((item) => {
      if (item.children && item.children.length > 0) {
        item.children.forEach((childItem) => {
          if (childItem.url && location.pathname.includes(childItem.url.split('?')[0])) {
            keys.push(childItem.key);
          }
        });
      } else {
        if (item.url && location.pathname.includes(item.url.split('?')[0])) {
          keys.push(item.key);
        }
      }
    });

    return keys;
  }, [location, navItems]);

  const openedSubmenu = useMemo(() => {
    return navItems.find(({ children }) =>
      children?.some(({ url }) => url && location.pathname.includes(url.split('?')[0])),
    );
  }, [navItems, location]);
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];
  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);

  useEffect(() => {
    if (selectedKeys.length > 0) {
      const timer = setTimeout(() => {
        updateDotPosition(selectedKeys[0]);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [selectedKeys, openKeys, updateDotPosition]);

  const items = useMemo(() => {
    if (
      userState.user?.role === getEnumValue('UserRole', 'SuperAdmin') ||
      userState.user?.role === getEnumValue('UserRole', 'Vendor')
    ) {
      return navItems
        .filter((navItem) => shouldShowScreen(navItem, undefined))
        .map((nav) => {
          const isSubMenu = nav.children?.length;
          return {
            key: nav.key,
            title: t(nav.title),
            label:
              isSubMenu && nav.url == null ? (
                <>
                  {t(nav.title)}
                  {!openKeys.includes(t(nav.title)) && nav.badgeComponent}
                </>
              ) : (
                <Link to={nav.url || ''} target={nav.newTab ? '__blank' : ''}>
                  {t(nav.title)}
                </Link>
              ),
            icon: nav.icon,
            children:
              isSubMenu &&
              nav.children &&
              nav.children
                .filter((navItem) => shouldShowScreen(navItem, undefined))
                .map((childNav) => ({
                  key: childNav.key,
                  label: (
                    <Link to={childNav.url || ''} target={nav.newTab ? '__blank' : ''}>
                      {t(childNav.title)}
                    </Link>
                  ),
                  title: t(childNav.title),
                  onClick: () => updateDotPosition(childNav.key),
                })),
          };
        });
    }

    const sidebarItemGroups = appState.appValues?.DashboardScreensGroup as {
      label: string;
      value: number;
    }[];

    const groupedItems = sidebarItemGroups.map((group) => {
      return {
        key: group.value,
        label: isCollapsed ? '' : <S.Title>{group.label.toUpperCase()}</S.Title>,
        type: 'group',
        children: navItems
          .filter((navItem) => shouldShowScreen(navItem, group.value))
          .map((nav) => {
            const isSubMenu = nav.children?.length;
            return {
              key: nav.key,
              title: t(nav.title),
              label:
                isSubMenu && nav.url == null ? (
                  <>
                    {t(nav.title)}
                    {!openKeys.includes(t(nav.title)) && nav.badgeComponent}
                  </>
                ) : (
                  <Link to={nav.url || ''} target={nav.newTab ? '__blank' : ''}>
                    {t(nav.title)}
                  </Link>
                ),
              icon: nav.icon,
              children:
                nav.children &&
                nav.children
                  .filter((navItem) => shouldShowScreen(navItem, undefined))
                  .map((childNav) => ({
                    key: childNav.key,
                    label: (
                      <Link to={childNav.url || ''} target={nav.newTab ? '__blank' : ''}>
                        {t(childNav.title)}
                        {childNav.badgeComponent && childNav.badgeComponent}
                      </Link>
                    ),
                    title: t(childNav.title),
                    onClick: () => updateDotPosition(childNav.key),
                  })),
            };
          }),
      };
    });

    return groupedItems;
  }, [isCollapsed, navItems, userState, t, updateDotPosition, appState]);

  function shouldShowScreen(nav: SidebarNavigationItem, sidebarItemGroup?: number): boolean {
    const isSubMenu = nav.children?.length;
    if (isSubMenu && !sidebarItemGroup) {
      return true;
    }
    if (appState.appValues == null && appState.rolesAndScreens == null) {
      return false;
    }
    const dashboardScreens = (appState.appValues as any)['DashboardScreens'];
    const rolesAndScreens = appState.rolesAndScreens || [];
    const userRole = userState.user?.role || 0;

    return nav.allow || isScreenAllowed(nav.key, dashboardScreens, rolesAndScreens, userRole, sidebarItemGroup);
  }

  return (
    <S.SiderContainer>
      <S.MenuContainer ref={menuRef}>
        <S.Menu
          mode="inline"
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={(keys) => setOpenKeys(keys as string[])}
          items={items}
        />
      </S.MenuContainer>
      <ProfileDropdown isCollapsed={isCollapsed} />
    </S.SiderContainer>
  );
};

export default SiderMenu;
