import { Badge } from '../BaseBadge/BaseBadge.styles';
import { Tooltip } from 'antd';
import { StyledBaseBadgeCounter } from './BaseBadgeCounter.styled';

interface BadgeCounterProps {
  count: number;
  isLoading: boolean;
  tooltipTitle: string;
  style?: React.CSSProperties;
}

const BadgeCounter: React.FC<BadgeCounterProps> = ({ count, isLoading, tooltipTitle, style }) => {
  return (
    <Tooltip title={tooltipTitle}>
      <StyledBaseBadgeCounter count={isLoading ? 0 : count} overflowCount={9999} style={style} />
    </Tooltip>
  );
};

export default BadgeCounter;
