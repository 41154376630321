import { useCountActiveAlerts } from '@app/api/alert.api';
import React from 'react';
import BaseBadgeCounter from '@app/components/common/BaseBadgeCounter/BaseBadgeCounter';

const AlertBadgeCounter: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const { data: alertCount, isLoading } = useCountActiveAlerts();

  return (
    <BaseBadgeCounter
      count={alertCount ?? 0}
      isLoading={isLoading}
      tooltipTitle={`${alertCount ?? 0} alerts`}
      style={style}
    />
  );
};

export default AlertBadgeCounter;
