import {
  BarChartOutlined,
  BellOutlined,
  FileTextOutlined,
  MailOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import React from 'react';
import { BsDatabaseFill } from 'react-icons/bs';
import { GrCompliance } from 'react-icons/gr';
import { MdOutlineSecurity } from 'react-icons/md';
import { AiOutlineFileProtect } from 'react-icons/ai';

import Book from '@app/components/icons/book/Book';
import Dashboard from '@app/components/icons/dashboard/Dashboard';
import HelpIcon from '@app/components/icons/help-icon/HelpIcon';
import LightBulb from '@app/components/icons/lightbulb/LightBulb';
import NotificationBell from '@app/components/icons/notification/NotificationBell';
import Report from '@app/components/icons/report/Report';
import SecurityShield from '@app/components/icons/securityshield/SecurityShield';
import Settings from '@app/components/icons/settings/Settings';
import WarningSquare from '@app/components/icons/warningsquare/WarningSquare';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';
import buildQuery from 'odata-query';
import { FaMoneyCheck, FaUsers } from 'react-icons/fa';
import AlertBadgeCounter from '@app/components/apps/alerts/AlertBadgeCounter/AlertBadgeCounter';

const manageGroupsOnRenderFilter = buildQuery({
  select: ['name', 'creationTime', 'lastUpdatedTime', 'numberOfUsers'],
});

const manageUsersOnRenderFilter = buildQuery({
  select: ['email', 'role', 'groupName', 'creationDate', 'lastLoginDate', 'status'],
  filter: {
    or: [
      { status: getEnumValue('UserStatus', 'Active') },
      { status: getEnumValue('UserStatus', 'Pending') },
      { status: getEnumValue('UserStatus', 'Blocked') },
    ],
  },
});

const activeAlertsOnRenderFilter = buildQuery({
  select: ['lastActivationTime', 'lastUpdatedTime', 'name'],
});

const alertsHistoryOnRenderFilter = buildQuery({
  select: ['creationTime', 'alertName', 'currentStatus', 'changeType'],
});

const manageAlertsOnRenderFilter = buildQuery({
  select: ['name', 'lastUpdatedTime', 'status'],
});

const recommendationsPageOnRenderFilter = buildQuery({
  select: [
    'findings',
    'severity',
    'easeOfFix',
    'estimatedWork',
    'productName',
    'isCompliant',
    'isExempted',
    'validationMethod',
  ],
});

//date,findings,changeType,changeDescription,productName,validationMethod
const recommendationHistoryOnRenderFilter = buildQuery({
  select: ['date', 'findings', 'changeType', 'changeDescription', 'productName', 'validationMethod'],
});

export const regulationInformationOnRenderFilter = buildQuery({
  select: ['findings', 'severity', 'productName', 'isCompliant', 'isExempted', 'controlIds', 'validationMethod'],
});

const insightsOnRenderFilter = buildQuery({
  select: ['title'],
});

// admin pages
const manageAccountsOnRenderFilter = buildQuery({
  select: [
    'name',
    'creationTime',
    'status',
    'microsoftApplicationStatus',
    'lastScanTimeEnd',
    'ownerEmail',
    'subscriptionName',
    'vendorName',
  ],
  filter: { status: 1 }, //hardcoded the value of Enabled account status enum since this url is loaded first after logging in and the enums endpoint might not be fetched yet
});
//vendorName,email,status
const manageResellersOnRenderFilter = buildQuery({
  select: ['vendorName', 'email', 'subscriptionName', 'status'],
});

const manageRecommendationOnRenderFilter = buildQuery({
  select: ['id', 'creationTime', 'productName', 'findings', 'severity', 'easeOfFix', 'status', 'validationMethod'],
  filter: { status: 1 }, //hardcoded the value of Enabled recommendation status enum since this url is loaded first after logging in and the enums endpoint might not be fetched yet
});

const manageStoriesOnRenderFilter = buildQuery({
  select: ['name', 'riskLevel', 'estimatedEffort', 'userImpact', 'status'],
});

const manageInsightsOnRenderFilter = buildQuery({
  select: ['creationTime', 'title', 'status', 'priority'],
});

const dataConnectorsOnRenderFilter = buildQuery({
  select: ['name', 'type', 'modelName', 'status', 'modelId'],
});

const manageReportsOnRenderFilter = buildQuery({
  select: ['name', 'description', 'status', 'url', 'creationTime'],
});

const manageSettingsOnRenderFilter = buildQuery({
  select: ['displayName', 'value', 'description'],
});

const manageRegulationsOnRenderFilter = buildQuery({
  select: ['name', 'status'],
  filter: { status: 1 }, //hardcoded the value of Enabled account status enum since this url is loaded first after logging in and the enums endpoint might not be fetched yet
});

const manageMessagingOnRenderFilter = buildQuery({
  select: ['id', 'lastUpdateTime', 'displayName', 'description'],
});

//name,timeSent,creationTime,lastUpdateTime,status
const manageNotificationOnRenderFilter = buildQuery({
  select: ['name', 'timeSent', 'creationTime', 'lastUpdateTime', 'status'],
});

const manageSubscriptionOnRenderFilter = buildQuery({
  select: ['name'],
  filter: { status: getEnumValue('SubscriptionTypeStatus', 'Enabled') },
});

const ManageTermsAndConditionsFilter = buildQuery({
  select: ['version', 'creationTime', 'lastUpdateTime', 'status', 'type'],
  filter: { status: 1 }, //hardcoded the value of Enabled account status enum since this url is loaded first after logging in and the enums endpoint might not be fetched yet
});

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  allow?: boolean;
  newTab?: boolean;
  badgeComponent?: React.ReactNode;
}

function getSideBarItems(regulations: { id: number; name: string }[]): SidebarNavigationItem[] {
  return [
    {
      title: 'common.homepage',
      key: 'HomePage',
      // TODO use path variable
      url: '/dashboard',
      icon: <Dashboard />,
    },
    {
      title: 'common.alert',
      key: 'Alerts',
      icon: <NotificationBell />,
      badgeComponent: <AlertBadgeCounter style={{ marginLeft: '15px' }} />,
      children: [
        {
          title: 'common.manageAlerts',
          key: 'Alerts',
          url: `/manage-alerts${manageAlertsOnRenderFilter}`,
        },
        {
          title: 'common.activeAlerts',
          key: 'ActiveAlerts',
          url: `/active-alerts${activeAlertsOnRenderFilter}`,
          badgeComponent: <AlertBadgeCounter style={{ marginLeft: '15px' }} />,
        },
        {
          title: 'common.alertsHistory',
          key: 'AlertsHistory',
          url: `/alerts-history${alertsHistoryOnRenderFilter}`,
        },
      ],
    },
    {
      title: 'common.manageAccounts',
      key: 'ManageAccounts',
      // TODO use path variable
      url: `/manage-accounts${manageAccountsOnRenderFilter}`,
      icon: <UserAddOutlined />,
    },
    {
      title: 'common.manageVendors',
      key: 'ManageVendors',
      // TODO use path variable
      url: `/manage-vendors${manageResellersOnRenderFilter}`,
      icon: <UsergroupAddOutlined />,
    },
    {
      title: 'common.manageRecommendations',
      key: 'ManageRecommendations',
      url: `/manage-recommendations${manageRecommendationOnRenderFilter}`,
      icon: <MdOutlineSecurity />,
    },
    {
      title: 'common.manageStories',
      key: 'ManageStories',
      url: `/manage-stories${manageStoriesOnRenderFilter}`,
      icon: <Book />,
    },
    {
      title: 'common.manageInsights',
      key: 'ManageInsights',
      url: `/manage-insights${manageInsightsOnRenderFilter}`,
      icon: <BarChartOutlined />,
    },
    {
      title: 'common.dataConnectors',
      key: 'DataConnectors',
      url: `/data-connectors${dataConnectorsOnRenderFilter}`,
      icon: <BsDatabaseFill />,
    },
    {
      title: 'common.manageReports',
      key: 'ManageReports',
      url: `/manage-reports${manageReportsOnRenderFilter}`,
      icon: <FileTextOutlined />,
    },
    {
      title: 'common.manageRegulations',
      key: 'ManageRegulations',
      url: `/manage-regulations${manageRegulationsOnRenderFilter}`,
      icon: <GrCompliance />,
    },
    {
      title: 'common.recommendations',
      key: 'Recommendations',
      icon: <SecurityShield />,
      children: [
        {
          title: 'common.recommendationsRepository',
          key: 'Recommendations',
          url: `/recommendations${recommendationsPageOnRenderFilter}`,
        },
        {
          title: 'common.history',
          key: 'RecommendationHistory',
          url: `/recommendation-history${recommendationHistoryOnRenderFilter}`,
        },
        // {
        //   title: 'common.active',
        //   key: 'ActiveRecommendations',
        //   url: '/active-recommendations',
        // },
      ],
    },
    {
      title: 'common.stories',
      key: 'Stories',
      url: '/stories',
      icon: <Book />,
    },
    {
      title: 'common.compliance',
      key: 'RegulationsCompliance',
      url: '/regulations-compliance',
      icon: <WarningSquare />,
    },
    {
      title: 'common.insights',
      key: 'Insights',
      url: `/insights${insightsOnRenderFilter}`,
      icon: <LightBulb />,
    },
    {
      title: 'common.reports',
      key: 'Reports',
      url: '/reports',
      icon: <Report />,
    },
    {
      title: 'Settings',
      key: 'GeneralSettings',
      icon: <Settings />,
      children: [
        {
          title: 'General',
          key: 'GeneralSettings',
          url: 'settings/general',
        },
        {
          title: 'Manage Users',
          key: 'ManageUsers',
          url: `settings/manage-users${manageUsersOnRenderFilter}`,
        },
        {
          title: 'Manage Groups',
          key: 'ManageGroups',
          url: `settings/manage-groups${manageGroupsOnRenderFilter}`,
        },
      ],
    },
    {
      title: 'Help Center',
      key: 'HelpCenter',
      icon: <HelpIcon />,
      url: 'https://kb.griffin31.com/',
      newTab: true,
    },
    {
      title: 'common.manageSettings',
      key: 'ManageSettings',
      url: `/manage-settings${manageSettingsOnRenderFilter}`,
      icon: <SettingOutlined />,
    },
    {
      title: 'Messaging',
      key: 'Messaging',
      url: `/messaging${manageMessagingOnRenderFilter}`,
      icon: <MailOutlined />,
    },
    {
      title: 'Manage Notifications',
      key: 'ManageNotifications',
      url: `/manage-notifications${manageNotificationOnRenderFilter}`,
      icon: <BellOutlined />,
    },
    {
      title: 'common.subscriptionTypes',
      key: 'SubscriptionTypes',
      url: `/subscription-types${manageSubscriptionOnRenderFilter}`,
      icon: <FaMoneyCheck />,
    },
    {
      title: 'Manage Terms And Conditions',
      key: 'ManageTermsAndConditions',
      url: `/manage-terms-and-conditions${ManageTermsAndConditionsFilter}`,
      icon: <AiOutlineFileProtect />,
    },
    {
      title: 'common.resellersManageUsers',
      key: 'ResellersManageUsers',
      url: `/manage-users${manageUsersOnRenderFilter}`,
      icon: <FaUsers />,
    },
  ];
}

export const useSideBarNavigation = (): SidebarNavigationItem[] => {
  const allowedScreens = useAppSelector((state) => state.app.allowedScreensAndActions?.screens) as string[];
  const regulations = useAppSelector((state) => state.user.regulations);
  const sidebarItems = getSideBarItems(regulations);
  const filteredSidebarItems = sidebarItems
    .filter((item) => allowedScreens?.includes(item.key))
    .map((item) => ({
      ...item,
      children: item.children?.filter((child) => child.allow || allowedScreens.includes(child.key)),
    }));
  return filteredSidebarItems;
};
